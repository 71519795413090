
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_WHITE,
} from '~/constants/General';
import { customLinkFieldShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    background: string().def('bg-white'),
    body: oneOfType([string(), nullable()]),
    sidenote: arrayOf(
      shape({
        text: oneOfType([string(), nullable()]),
        link: arrayOf(shape(customLinkFieldShape).loose),
      }).loose
    ),
    link: arrayOf(shape(customLinkFieldShape).loose),
  },
  computed: {
    colorSchemeRichText() {
      return (
        {
          'bg-blue-500': COLOR_SCHEME_BLUE,
          'bg-blue-25': COLOR_SCHEME_LIGHT,
          'bg-white': COLOR_SCHEME_WHITE,
          'bg-transparent': COLOR_SCHEME_WHITE,
          'bg-dark-475': COLOR_SCHEME_DARK,
        }[this.background] || COLOR_SCHEME_WHITE
      );
    },
    colorSchemeSideNote() {
      return (
        {
          'bg-blue-500': COLOR_SCHEME_DARK,
          'bg-blue-25': COLOR_SCHEME_LIGHT,
          'bg-white': COLOR_SCHEME_LIGHT,
          'bg-transparent': COLOR_SCHEME_LIGHT,
          'bg-dark-475': COLOR_SCHEME_DARK,
        }[this.background] || COLOR_SCHEME_LIGHT
      );
    },
  },
};
