
import { arrayOf, nullable, oneOf, oneOfType, shape, string } from 'vue-types';
import {
  SIZE_MEDIUM,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_DARK,
} from '~/constants/General';
import { customLinkFieldShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    text: oneOfType([string(), nullable()]),
    link: arrayOf(shape(customLinkFieldShape).loose),
    colorScheme: oneOf([COLOR_SCHEME_LIGHT, COLOR_SCHEME_DARK]).def(
      COLOR_SCHEME_LIGHT
    ),
  },
  data() {
    return {
      SIZE_MEDIUM,
    };
  },
  computed: {
    dark() {
      return this.colorScheme === COLOR_SCHEME_DARK;
    },
  },
};
