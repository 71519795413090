import { render, staticRenderFns } from "./RichText.vue?vue&type=template&id=524828d0&scoped=true"
import script from "./RichText.vue?vue&type=script&lang=js"
export * from "./RichText.vue?vue&type=script&lang=js"
import style0 from "./RichText.vue?vue&type=style&index=0&id=524828d0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524828d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsRichText: require('/src/components/atoms/RichText.vue').default,OrganismsSideNote: require('/src/components/organisms/SideNote.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,AtomsModuleWrapper: require('/src/components/atoms/ModuleWrapper.vue').default})
