
import { arrayOf, nullable, oneOf, oneOfType, shape, string } from 'vue-types';
import {
  COLOR_SCHEME_WHITE,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  SIZE_MEDIUM,
} from '~/constants/General';
import { customLinkFieldShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    colorScheme: oneOf([
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_DARK,
    ]).def(COLOR_SCHEME_WHITE),
    html: oneOfType([string(), nullable()]),
    link: arrayOf(shape(customLinkFieldShape).loose),
  },
  data() {
    return {
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_DARK,
      SIZE_MEDIUM,
    };
  },
};
